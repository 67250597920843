#freetoolkit {
  padding-bottom: 12.5rem;
  .toolkits{
    .section{
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      margin-bottom: 3rem;
      @include breakpoint(sm-down) {
        padding-right: 1.031rem;
        padding-left: 1.031rem;
      }
      h3 {
        width: 100%;
        font-weight: 400;
        padding-bottom: 1rem;
        border-bottom: 1px solid #CCCCCC;
        font-size: 14px ;
        color: #141514;
        line-height: 22px;
        margin-bottom: 0px;
      }
    }
  }
  #freetoolkit-header{
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
    text-align: center;
    @include breakpoint(sm-down) {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    h1 {
      @include breakpoint(sm) {
          font-size: 5rem;
          line-height: 4.6rem;
      }
    }
  }
  .tools-card-wrapper{
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
    row-gap: 16px;
    @include breakpoint(sm-down) {
    margin-top: 0px;
    gap: 25px;
    }
  }
  .tools {
      width: 100%;
      &:hover .inner{
          border: 1px solid rgba(0, 0, 0, 1);
          cursor: pointer;
      }
      .inner {
          transition: all .05s cubic-bezier(0.55, 0.085, 0.68, 0.53);
          display: flex;
          border: 1px solid rgba(0, 0, 0, 0.20);
          padding: 15px;
          padding-bottom: 0px;
          flex-direction: row-reverse;
          min-height: 221px;
          @include breakpoint(sm-down) {
              flex-direction: column;
              padding: 10px 25px;
              min-height: 150px;
              .icon{
                  display: flex;
                  margin-right: 25px;
                  margin-top: 35px;
              }
          }
          .icon{
              @include breakpoint(sm) {
                  position: absolute;
              }
          }
          .content{
              padding: 35px 0px 0px 25px;
              @include breakpoint(sm-down) {
                  padding: 25px 0px 25px 0px;
              }
              .tool-description{
                  display: inline-block;
                  align-self: flex-end;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 24px;
                  letter-spacing: -0.3px;
                  padding-right: 50px;
                  color: #141514;
                  margin: 0;
                  @include breakpoint(sm-down) {
                        // display: none;
                         font-size: .75rem;
                         line-height: 1.25rem;
                  }
              }
              .tool-name{
                  font-weight: 500;
                  font-size: 24px;
                  line-height: 31px;
                  letter-spacing: -0.3px;      
                  color: #000000;
                  margin: 0;
                  margin-bottom: 22px;
                  @include breakpoint(sm-down) {
                      font-size: 1.2rem;
                      margin-bottom: 15px;
                      width: 100%;
                  }
              }
          }
      }
  }
}
.all-tools-btn {
    width: 148px;
    height: 48px;
    margin: auto;
    background: #F9F7F7;
    border-radius: 1px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    /* or 186% */

    text-align: center;
    margin-top: 64px;
    transition: all 0.3s ease-in-out;
    &:hover {
        background-color: #f4f1f1;
    }
}