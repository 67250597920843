.show-nav {
  opacity: 1 !important;
  visibility: visible !important;
}

#navigation {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  cursor: default;
  background: white;
  left: 0;
  right: 0;
  z-index: 5;
  width: max-content;
  transition: all .1s cubic-bezier(0.5, 0.1, 0.6, 0.5);

  .submenu {
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    width: auto;
    .row {
      margin: 0px !important;
    }

    .col-md-3 {
      @include breakpoint(md) {
        max-width: 23%;
      }
    }

    &.auto-width {
      @include breakpoint(md) {
        width: auto;
      }
    }

    .announcement {
      display: flex;
      flex-direction: column;
      color: #ffffff;
      margin-left: auto;
      z-index: 999;

      h2 {
        font-size: 14px;
        line-height: 18px;
        font-weight: bold;
        letter-spacing: 0.005em;
        margin: 0;
        color: #ffffff;
      }

     .announcement-title {
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.3px;
        max-width: 195px;
        margin: 32px 0px;
        color: #ffffff;
      }

      .btn {
        transition: $transition_default;
        border: none;
        color: black !important;
        align-self: baseline;
        margin-top: auto;
        width: 100%;
        padding: 20px 0px;
        &:hover{
          opacity: .8;
        }
      }
    }

    .column {
      transition: $transition_default;
      h4,
      p {
        margin: 0;
        padding: 0;
      }

      padding: 32px;

      &.main-column {
        min-width: 265px;
        background: #fcfcfc;
      }

      ul {
        padding: 0;
        margin: 0 auto;
        position: relative;
        list-style: none;

        li {
          float: none;
          margin-right: 0;
        }
      }

      h4 {
        margin: 0;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        vertical-align: middle;
        line-height: normal;
      }

      .title {
        padding-bottom: 25px;
        display: flex;
        align-items: center;
        flex-direction: row;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-top: 0;

        .tag {
          background-color: #f2f2f2;
          font-size: 11px;
          line-height: 16px;
          border-radius: 20px;
          padding: 2px 8px;
          letter-spacing: 0.01em;
          font-weight: normal;
          margin-left: 8px;
          line-height: normal;
        }

        h4 {
          font-weight: bold;
        }

        svg {
          margin-top: 2px;
          margin-right: 5px;
        }
      }

      .pages {
        transition: $transition_default;
        cursor: pointer;

        .more-link {
          padding: 10px 8px 12px;
          opacity: 0.3;
          transition: $transition_default;
          display: block;
          margin-bottom: 64px;

          span {
            transition: $transition_default;
          }

          &:hover {
            opacity: 1;
            margin-left: 2px;

            span {
              padding-left: 5px;
            }
          }
        }

        .page {
          transition: $transition_default;
          cursor: pointer;

          &:nth-child(1) {
            margin-top: 10px;
          }

          .inner {
            border-radius: 5px;
            padding: 0;
            .tag {
              background-color: #f2f2f2;
              font-size: 11px;
              line-height: 16px;
              border-radius: 20px;
              padding: 2px 8px;
              letter-spacing: 0.01em;
              font-weight: normal;
              margin-left: 2px;
              line-height: normal;
              transition: all 0.05s ease;
            }
          }

          &:hover .tag {
            background-color: #000000;
            color: #ffffff;
          }

          p {
            font-size: 11px;
            line-height: 16px;
            opacity: 0.6;
            margin-top: 0.3125rem;
            letter-spacing: 0.01em;
          }
        }
      }
    }
  }
}

#header {
  position: static;
  padding: 16px;
  margin: 0;

  @include breakpoint(sm) {
    padding-left: 0;
    padding-right: 0;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .navigation-parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @include breakpoint(md) {
      justify-content: start;
      gap: 2rem;
    }
  }
  .logo {
    display: block;
    width: 2.25rem;

    svg {
      z-index: 12;
      fill: $dark;
      position: relative;
      display: block;
    }
  }

  button {
    outline: none !important;
    z-index: 12;
    position: relative;
    display: block;
    margin-left: auto;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: transparent;

    &.right {
      margin-left: auto;
    }
  }

  li {
    cursor: pointer;

    a {
      transition: all 0.12s;
    }

    &.cta {
      display: flex;
      align-items: center;

      a {
        background-color: black;
        opacity: 1;
        padding: 8px 24px;
      }

      .no-background {
        background: none;
        color: black;
        border: none;
      }

      line-height: 3rem;
    }

    a:not(.btn) {
      text-align: left;
      font-size: 1rem;
      line-height: 3rem;
      color: $dark;
      text-decoration: none;
    }

    &.mobile-adjusted {
      @include breakpoint(md-down) {
        position: inherit !important;

        .resources-navigation {
          padding-left: 21px;
        }
      }
    }

    &.has-submenu {
      position: relative;

      ul {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        cursor: default;
        padding: 10px;
        z-index: 5;
        border-radius: 3px;

        &.grey-bg {
          li:hover {
            background: #f8f8f8;
          }
        }
      }

      li {
        opacity: 1;
        display: flex;
        align-items: center;
        padding: 10px 8px 12px;
        line-height: 0;
        border-radius: 5px;
        transition: all 0.05s ease;

        &:hover {
          background-color: #f2f2f2;
        }

        a {
          opacity: 1;
          display: flex;
          align-items: center;
          line-height: 0.6875rem;
          padding: 0 1.25rem;

          &.--no-flex {
            display: block;
            padding: 0 8px;
          }
          &.--no-wrap {
            white-space: nowrap;
          }

          h4 {
            font-size: 0.875rem;
            margin-bottom: -15px;
            margin-top: -5px;
            font-weight: 500;
          }

          .icons {
            margin-right: 1.5625rem;
          }

          p {
            font-size: 11px;
            font-weight: 350;
            color: #787878;
            margin: 0;
          }

          .new {
            position: relative;
            bottom: 5px;
            font-size: 11px;
            color: #1b16ff;
          }
        }
      }

      &:hover {
        ul {
          visibility: visible;
          opacity: 1;
          transform: translateY(6px);
        }
      }
    }
  }

  .creator-studio {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    .new-badge {
      padding: 2px 6px;
      width: 33px;
      height: 15px;
      background: linear-gradient(180deg, #ffe9f0 0%, #faecdb 51.04%, #d6ebe4 100%);
      border-radius: 10px;
      flex: none;
      order: 1;
      flex-grow: 0;
      font-weight: 500;
      font-size: 9px;
      line-height: 15px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #141514;
    }
  }

  .col-xs-7 li {
    line-height: 3.125rem;
  }
}

#menu-overlay {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  display: none;
  opacity: 0;
  transition: all 0.2s ease;
  overflow-x: hidden;

  .container,
  .row {
    height: 100%;
  }

  ul {
    margin-top: 85px;
    padding: 0px 49px 0px 30px;
    list-style: none;
    width: 100%;

    li {
      border-bottom: 1px solid rgba(0, 0, 0, 0.25);
      cursor: pointer;

      @media screen and (max-height: 560px) {
        margin-top: 0.2rem;
        margin-bottom: 0.2rem;
      }

      a {
        padding: 12.5px 0;
        color: black !important;
        text-decoration: none;
        font-size: 1.625rem;
        line-height: 2.375rem;
        display: block;
        box-sizing: border-box;
        width: 100%;
      }

      &.dropdown-item {
        margin: 0;

        .dropdown {
          display: flex;
          align-items: center;
          flex-direction: row;

          .arrow-ico {
            margin-left: auto;
            transition: all 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);

            &.open {
              transform: rotate(180deg);
            }
          }
        }

        .content {
          ul {
            padding: 0;
            margin: 0 0 16px 0;

            li {
              border: none;
              margin: 0;

              &:not(:first-child) {
                padding-bottom: 12px;
              }

              p {
                padding: 0;
                margin: 0;
                font-size: 11px;
                line-height: 16px;
                letter-spacing: 0.01em;
                opacity: 0.75;
              }

              a {
                font-size: 16px;
                line-height: 26px;
                font-weight: 400;
                padding-top: 0;
                padding-bottom: 0;
                margin: 0;
              }
            }
          }

          .content-title {
            font-weight: bold;
            padding-bottom: 6px;

            svg {
              margin-right: 5px;
            }
          }

          display: none;
          transition: all 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
          animation: toggle forwards 0.2s ease-in-out;
          opacity: 0;

          &.show {
            display: block;
            opacity: 1;
          }
        }
      }

      @keyframes toggle {
        from {
          opacity: 0;
          transform: scaleY(0);
          transform: translateY(-5px);
        }

        to {
          opacity: 1;
          transform: translateY(0px);
          transform: scaleY(1);
        }
      }

      @keyframes menuOverlay {
        from {
          opacity: 0;
          transform: translateY(20px);
        }

        to {
          opacity: 1;
          transform: translateY(0px);
        }
      }

      &.has-submenu {
        margin-bottom: 0;

        a {
          font-weight: bold;
        }
      }

      &.is-submenu {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  div.cta {
    // bottom: 10px;
    // left: 30px;
    // width: calc(100% - 60px);
    width: 100%;
    padding: 0px 49px 0px 30px;
    margin: auto;
    margin-top: auto;
    margin-bottom: 25px;

    .no-background {
      background: none;
      color: black;
      border: none;
    }

    a {
      display: block;
      border-color: $dark;

      span {
        font-weight: 400;
      }
    }
  }
}

.with-menu {
  overflow-y: hidden;

  #menu-overlay {
    animation: menuOverlay forwards 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    display: block;
    overflow-y: scroll;
    overflow-x: none !important;
    box-sizing: content-box;
    opacity: 1;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: -17px;
    /* Increase/Decrease this value for cross-browser compatibility */
  }

  #header {
    .logo svg {
      fill: rgb(0, 0, 0);
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: rgb(0, 0, 0);
    }
  }

  #header-container {
    z-index: 200;
    background: white;
  }
}

#header-container {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background: white;
  z-index: 200;
  transition: all 0.12s ease-in-out;
}