.mobile-center{
  @include breakpoint(sm-down) {
    text-align: center !important;

  }
}
.ugc-global-section {
    background: $light_grey;
    padding: 32px 20px 64px;
    text-align: center;

      @include breakpoint(md-down) {
        .whatwedo_video{
            display: none;
          }
      }

    .whatwedo_description-title{
        margin-top: 2rem;
        font-weight: 400;
        font-size: 2.25rem;
        line-height: 2.62rem;
        letter-spacing: -1px;
        text-align: left;
        display: flex;
        flex-direction: column;
        
        @include breakpoint(md) {
            font-weight: 500;
            font-size: 3.5rem;
            line-height: 4rem;
            letter-spacing: -1.4px;
        }
    }
    .whatwedo-btn{
        &:hover{
            opacity: 0.9;
        }
    }
    @include breakpoint(xs) {
        padding: 32px 20px 64px;
        .whatwedo_description-title{
            margin-top: 2rem;
            margin-bottom: 24px !important;

        }
    }
    @include breakpoint(sm) {
        padding: 128px 50px;
        .whatwedo_description-title{
            margin-bottom: 32px !important;
            margin-top: 0;
        }
    }
    @include breakpoint(md) {
        .whatwedo_description-title{
            text-align: left;
      
        }

    }
    .whatwedo_image, .whatwedo_video {
        display: none;
        border-radius: 5px;
        @include breakpoint(md) {
            width: 100%;
            display: block;
            border-radius: 10px;
        }
    }
    .row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include breakpoint(sm-down) {
            margin: 0;
        }
        .whatwedo_description-subtext{
            margin-bottom: 0rem !important;
            text-align: left;
            @include breakpoint(md) {
                opacity: 0.75;
                text-align: left;
            }  
        }
        .cta-whatwedo{
            .whatwedo-btn{
                border: none;
                display: block;
                float: left;
                margin-top: 2rem;
                @include breakpoint(md) {
                    width: max-content;
                    margin-top: 3rem;
                }  
            }
            margin: 0rem 0px 0rem 0px !important;
        }
    }
    .new-badge{
        padding: 6px 12px;
        width: fit-content;
        height: fit-content;
        background: linear-gradient(180deg, #FFE9F0 0%, #FAECDB 51.04%, #D6EBE4 100%);
        border-radius: 360px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #141514;
        margin-bottom: 2rem;
    }
}