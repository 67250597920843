#section-normal {
  margin-bottom: 3rem;
  min-height: calc(100vh - 34rem);
  box-sizing: border-box;
  h3 {
    margin-bottom: 20px;
    margin-top: 30px;
  }
  a {
    font-size: inherit;
    text-decoration: underline;
  }
  p {
    margin-bottom: 10px;
  }
  ul {
    margin-top: 20px;
    margin-bottom: 20px;
    list-style: disc;
    padding-left: 20px;
    li {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
  }
}