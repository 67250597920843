$--green: #13825D;
$--inactive-border: #D9D9D9;
.inline-input__wrapper{
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: -0.4px;
  color: #000000;
}
.inline-input__wrapper[contenteditable='true'] {
    outline: none !important;
    cursor: text;
    display: inline-block;
    &:empty {
      &:before {
        content: attr(placeholder);
        // font-style: italic;
        opacity: 0.15;
      }
    }
  }
  .roas-left-controls{
    flex: 1 1 0;
    .roas-control-wrapper{
      display: flex;
      flex-direction: column;
      row-gap: 40px;
      .roas-input-box{
        border-bottom:  1px solid rgba(0, 0, 0, 0.1);
        &.--with-toggle{
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .wrapper{

          }
          &:hover {
            .toggle-label {
              opacity: 1;
            }
            .toggle-wrpper {
              opacity: 1;
            }
          }
        }
        .header{
            display: flex;
            align-items: center;
            gap: 4px;
            font-weight: 500;
            font-size: 16px;
            line-height: 29px;
          &.--black{
            color: #000000;
          }
          .question-circle{
            cursor: pointer;
            &:hover{
              opacity: 1;
            .--tooltip{
              opacity: 1;
            }
            }
            .--tooltip{
              bottom: 155%;
              margin-left: -69px;
            }
            position: relative;
          }
        }
        .input-wrapper{
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;
          padding-bottom: 4px;
          &.--with-toggle{
            justify-content: space-between;
            align-items: center;
            .control{
              display: flex;
              align-items: center;
              gap: 8px;
            }
          }
          &:hover{
            .copy-button_wrapper{
              visibility: visible;
            }
          }
          &:focus-within{
            .copy-button_wrapper{
              visibility: none;
            }
          }

          .doller-sign{
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            color: rgba(0, 0, 0, 0.5);
          }
          .copy-button_wrapper{
            position: relative;
            visibility: hidden;
            width: 32px;
            height: 32px;
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: all 0.1s ease-in-out;
            display: flex !important;
            svg{
                transition: all 0.1s ease-in-out;
            }
            &:hover{
                border: 1px solid rgba(0, 0, 0, 0.5);
                box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.15);
                .--tooltip{
                    opacity: 1;
                }
            }
            .--tooltip{
                width: max-content;
                margin-left: -40px;
                bottom: 150%;
            }
            .--sucess{
                background: #D6EBE4;
                color: black;
                display: flex;
                font-weight: 400;
                font-size: 11px;
                line-height: 16px;
                align-items: center;
                justify-content: center;
                gap: 6px;
                width: max-content;
                margin-left: -25px;
                &::after{
                    border-color: #D6EBE4 transparent transparent transparent;
                }

            }
            &:active{
                svg{
                    opacity: 0.5;
                }
            }
        }
        }
      }
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .graph-section-wrapper{
    flex: 1 1 0;
    background: #FFFFFF;
    border: 1px solid #DCDCDC;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
    border-radius: 1px;
    padding: 48px;
    display: flex;
    flex-direction: column;
    gap: 48px;
    min-height: 620px;
    box-sizing: border-box;
    position: relative;
    transition: border 0.3s ease-in-out;
    @include breakpoint(sm-down) {
      padding: 16px;
      min-height: 550px;
    }
    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.25);
      .roas-save-png-btn {
        opacity: 0.8;
      }
    }
    .roas-progress-wrapper{
      background: #FFFFFF;
      border: 1px solid #DCDCDC;
      box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      padding: 38px;
      @include breakpoint(sm-down) {
        padding: 16px;
      }
      .roas-progress-bar {
        margin-top: 16px; 
        position: relative;
        .progress-wrap {
          display: flex;
          align-items: center;
          font-weight: 600;
          .progress-value-wrapper{
            .title{
              position: absolute;
              // left: 0%;
              transform: translate(0%, -100%);
              line-height: 16px;
              text-align: center;
              display: flex;
              align-items: center;
              gap: 4px;
              .circle{
                background: #000000;
                opacity: 0.5;
                width: 8px;
                height: 8px;
                border-radius: 50%;
              }
              .title-value{
                font-weight: 500;
                font-size: 11px;
                line-height: 16px;
                letter-spacing: 0.01em;
                color: #000000;
              }
            }
            .value-wrapper{
              display: flex;
              align-items: center;
              gap: 8px;
              .value{
                font-weight: 500;
                font-size: 32px;
                line-height: 38px;
              }
              .percentage{
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
                color: rgba(0, 0, 0, 0.5);
              }
            }
          }
        }
        .progress-wrap .bar {
          background: #ECECEC;
          height: 2px;
          width: 100%;
          margin-left: 8px;
          position: relative
        }
         .progress-wrap .bar .target {
          position: absolute;
          left: 80%;
          transform: translate(-50%, -100%);
          padding-bottom: 5px; 
          line-height: 11px;
          text-align: center;
          display: flex;
          flex-direction: column;
        }
         .progress-wrap .bar .target small {
          color: rgba(0, 0, 0, 0.5);
          text-transform: uppercase;
          font-weight: 400;
          font-size: 8px;
          line-height: 16px;
          text-align: center;
          letter-spacing: 0.01em;
          white-space: nowrap
        }
         .progress-wrap .bar .target .expected-revenue {
          font-weight: 700;
          font-size: 11px;
          line-height: 16px;
          letter-spacing: 0.01em;
          color: #C48231;
        }
        .progress-wrap .bar .target:after {
          content: "";
          display: block;
          position: absolute;
          width: 2px;
          height: 12px;
          top: 100%;
          left: 50%;
          background: #EFEFEF;
          transform: translate(-50%, -4px)
        }
        .progress-wrap .bar .target.--safe:after {
          background: $green;
        }
        
        .progress-wrap .bar .progress {
          min-width: 12px;
          height: 2px;
          max-width: 100%;
          background: #000000;
          &.--safe {
            background: $green !important;
          }
          &.--warning {
            background: $yellow !important;
          }
          &.--danger {
            background: $red !important;
          }
        }
        
        
    }
    }

  }
  .switch-mode-wrapper{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
    margin-top: 32px;
    span {
      cursor: pointer;
      text-decoration: underline;
    }
}

.toggle{
  cursor: pointer;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  // justify-content: space-between;
  gap: 16px;
  .toggle-label{
      font-weight: 400;
      font-size: 11px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #000000;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
  }
}
.toggle-wrpper {
  display: inline-block;
  width: 40px;
  opacity: 0.2;
  transition: opacity 0.3s ease-in-out;
}

.toggle-input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  
  &:active {
      + .toggle-track {
          .toggle-control {
              right: 14px;
          }
      }
  }
  
  &:checked {
      + .toggle-track {
          transition: box-shadow 180ms ease-out;
          box-shadow: inset 0 0 0 20px $--green;
          
          .toggle-control {
              right: 0;
              left: 20px;
              background-color: #FFFFFF;
          }
      }
  }
  
  &:checked:active {
      + .toggle-track {
          .toggle-control {
              left: 14px;
              right: 0;
          }
      }
  }
}

.toggle-track {
  position: relative;
  height: 16px;
  border-radius: 16px;
  padding: 2px;
  background: #D9D9D9;
  transition: box-shadow 200ms ease-in;
  box-shadow: 
      inset 0 0 0 0px $--green, 
      inset 0 0 0 2px $--inactive-border;
}

.toggle-control {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 20px;
  height: 14px;
  width: 14px;
  background-color: #FFFFFF;
  border-radius: 50%;
  // box-shadow: 
  //     0 0 1px 1px rgba(0, 0, 0, 0.15), 
  //     0 4px 4px rgba(0, 0, 0, 0.2);
  transition: 200ms cubic-bezier(0.65, 1.15, 0.75, 1.25);
  margin: auto;
}
.roas-refresh{
  right: 12px !important;
  bottom: 12px !important;
  @include breakpoint(sm-down) {
    display: none !important;
  }
}


.ib-graph {
  // height: 328px;
  height: 296px;
  width: calc(100% - 36px);
  margin-left: 36px;
  position: relative;
  .ib-graph__left-axis {
    position: absolute;
    left: 0px;
    height: 100%;
    width: 1px;
    background-color: #D9D9D9;
    &::before {
      content: '';
      height: 6px;
      width: 1px;
      background-color: #D9D9D9;
      position: absolute;
      top: -6px;
      left: 0px;
    }
  }
  // .ib-graph__bottom-axis {
  //   position: absolute;
  //   bottom: 0px;
  //   width: 100%;
  //   height: 1px;
  //   background-color: #D9D9D9;
  // }
  .ib-graph__horizontal-levels {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    .ib-graph__level {
      width: 100%;
      height: 1px;
      background-color: #D9D9D9;
      position: relative;
      &::before {
        content: '';
        height: 1px;
        width: 6px;
        background-color: #D9D9D9;
        position: absolute;
        top: 0px;
        left: -6px;
      }
      .ib-graph__level-label {
        position: absolute;
        width: 36px;
        left: -40px;
        top: -8px;

        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.01em;

        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
  .ib-graph__bars {
    display: flex;
    gap: 24px;
    padding: 0px 24px;
    height: 100%;
    z-index: 1;

    .ib-graph__bar-progress {
      position: absolute;
      bottom: 0px;
      width: 100%;
      background-color: #ffffff;
      background: linear-gradient(180deg, var(--color-opacity) 0%, var(--color-solid) 100%);
      transition: height 0.5s ease-in-out;
      &:hover {
          background: var(--color-solid);
        .ib-graph__bar-value {
          opacity: 1;
        }
      }
    }
    .ib-graph__bar-value {
      position: absolute;
      bottom: 50%;
      left: 50%;
      // transform: translate(-50%,50%);
      transform: translate(-100%,50%);
      background: #C27210;
      color: #ffffff;
      padding: 2px 8px;
      border-radius: 16px;
      z-index: 1;
      // transition: all 0.05s linear;

      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.01em;
      opacity: 0;
    }
    .ib-graph__bar-label {
      position: absolute;
      bottom: -12px;
      transform: translateY(100%);
      font-weight: 400;
      // white-space: nowrap;

      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: rgba(0, 0, 0, 0.4);
      text-align: center;
      width: 100%;
    }
    .ib-graph__bar {
      flex: 1 1 0;
      position: relative;
      cursor: pointer;
      
    }
  }
}
.roas-save-png-btn {
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 0.75);

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 6px;
  cursor: pointer;

  position: absolute;
  bottom: 24px;
  right: 24px;

  opacity: 0;
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 1 !important;
  }
}

.roas-result-heading {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: center;

  color: #13825D;
  &.--green {
    color: #13825D;
  }
  &.--yellow {
    color: #C27210;
  }
  &.--red {
    color: #A84969;
  }
}
.oas-result-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;

  color: #000000;
}