#cost-calculator-header {
    text-align: center;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    h1 {
        @include breakpoint(sm) {
            font-size: 4rem;
            line-height: 4.6rem;
        }
    }
    p br {
        @include breakpoint(sm-down) {
            display: none;
        }
    }
}

#cost-calculator-form {
    max-width: 700px;
    margin: 50px auto 60px;
    text-align: center;
    input {
        background: transparent;
        width: 90%;
        max-width: 350px;
        border: 1px solid #000000;
        outline: none;
        height: 70px;
        padding: 5px 25px;
        box-sizing: border-box;
        margin-bottom: 10px;
        @media screen and (min-width: 700px) {
            margin-bottom: 0px;
            margin-right: 10px;
        }
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgba(0, 0, 0, 0.25);
    }
    }
    button {
        background-color: $dark;
        color: white;
        height: 70px;
        border: none;
        padding: 10px 0px;
        width: 224px;
        cursor: pointer;
        border-radius: 0;
        outline: none !important;
    }
}

#collaboration-cost-calculator {
    margin-bottom: 80px;
    .collaboration-loader{
        padding: 50px !important;
       .data{
           margin-top: 50px;
           div{
               margin-top: 25px;
           }
       }
       .loader-square--medium, .loader-square--big{
           margin-top: 22px;
       }
    }
    .card {
        border: 1px solid #dcdcdc;
        box-sizing: border-box;
        box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
        border-radius: 1px;
        padding: 20px 15px;
        &.p-tb-60{
            padding: 60px 40px;
        }
        @include breakpoint(sm) {
            padding: 20px 25px;
        }
        .title{
            display: flex;
            text-align: center;
            p{
                font-size:  0.875rem;
                font-weight: 500;
                align-items: center;
                display: flex;
                justify-content: center;
                margin: 0 auto;
            }
        }
    }
    .error {
        text-align: center;
        color: red;
        margin-top: -20px;
        margin-bottom: 60px;
    }
    .maintenance {
        text-align: center;
        color: red;
        margin-top: 40px;
        margin-bottom: 60px;
    }
}
#cost-calculator-result {
    margin-bottom: 60px;
    padding: 60px 30px !important;
    & > div {
        @include breakpoint(sm) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
    .profile-info {
        position: relative;
        @include breakpoint(md) {
            padding-right: 58px;
            padding-left: 30px;
        }
        margin: 40px 0;
        text-align: center;
        img {
            width: 125px;
            height: 125px;
            border-radius: 50%;
            cursor: pointer;
        }
        .username {
            font-weight: 700;
            margin: 10px 0;
            cursor: pointer;
        }
        span:not(.tooltip) {
            font-size: 14px;
            line-height: 1;
            color: $dark_sub;
        }
        .tooltip{
            right: 18%;
            text-align: left;
            p, span{
                font-size: 11px !important;
                line-height: 16px;
                letter-spacing: -0.3px;
                color: white;
            }
            .tooltip-infos{
                p{
                    margin:0;
                }
                .tooltip-subtitle{
                    margin-bottom: 21px;
                    opacity: .5;
                }
                .tooltip-text{
                    p{
                        margin: 2px 0;
                    }
                    span{
                        color: white;
                        font-weight: 400;
                    }
                }
            }

        }
        .stats {
            border-top: 1px solid rgba(0, 0, 0, 0.25);
            border-bottom: 1px solid rgba(0, 0, 0, 0.25);
            padding: 25px;
            //margin: 60px 0 45px;
            p {
                margin: 0;
                font-weight: 500;
                line-height: 1;
            }
        }
        a {
            width: 100%;
            font-weight: 700;
            display: block;
            margin-bottom: 40px;
            @include breakpoint(md) {
                margin-bottom: 0;
            }
        }
.estimated-total{
    margin-top: 50px;
    .card{
        padding:  40px 0;
        position: relative;
    }
    .border{
        position: absolute;
        height: 10px;
        bottom: 0;
        width: 100%;
        background-image: linear-gradient(90deg, #FFE9F0 0%, #FAECDB 51.04%, #D6EBE4 100%);;
    }
    p{
        color:black;
        font-size: 14px;
        margin: 0;
        font-weight: 400;
    }
    .result{
        display: inline-flex;
        .min-range, .max-range{
            margin: 15px;
            color:black;
            font-size: 2.5rem;
            font-weight: 700; 
        }
        .dash {

            position: relative;
            top: 5px;
            border:2px solid #E0E0E0;
            margin:24px 2px;
            transform: rotate(90deg) !important;

        }
    }
}

.estimated-total-selector{
    margin-top: 15px;
    .card{
        padding: 10px 25px;
    }
    .selector:not(:last-child){
        border-bottom: 1px solid #DCDCDC;
    }
    .selector{
        display: flex;
        align-items: center;
        padding: 15px 0;
        margin: 0;
        span{
            color: black;
            font-size: 24px;
            font-weight: 700;
            margin-left: 25px
        }
        svg{
            width: 42px;
            height: 42px;
        }
    }
    .controle{
        display: flex;
        margin-left: auto;

        .btn:first-child svg{margin-right: 15px;}
        .btn svg{
            cursor: pointer;
            transition: all .2s ease-in-out;
            width: 34px;
            height: 34px;
            &:hover circle{
                
                fill: #D0D0D0;
            }
        }
    }
}

    }
    .posts-info {
        @include breakpoint(md) {
            border-left: 1px solid rgba(0, 0, 0, 0.1);
            padding-left: 50px;
        }
        padding-bottom: 25px;
        text-align: center;
        & > .row > div {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }

        .stats {
            .stats-title{
                p{
                    font-size: 14px;
                    font-weight: 400;
                    text-align: left;
                    margin:0;
                    padding: 25px 0;
                }
            }

            p {
                font-weight: 700;
                margin-bottom: 0;
                margin-top: 5px;
                line-height: 1;
            }
            span {
                font-size: 14px;
                line-height: 16px;
                color: $dark_sub;
                display: block;
                margin-top: 5px;
                opacity: .5;
            }
         

            @include breakpoint(sm-down) {
                .stats-title p{
                    text-align: center;
                }
                .row>div{
                    padding: 15px 0;
                }
            }
            .likes svg {
                fill: $red;
            }
            .engagement svg {
                fill: $green;
            }
            .followers svg {
                width: 21px;
                height: 21px;
                fill: $yellow;
            }
        }
        .graph {
            text-align: left;
            p {
                font-weight: 700;
                margin-top: 0;
            }
            .curve-inbeat {
                .stroke {
                    stroke: $red;
                    stroke-width: 2;
                }
                .fill {
                    fill: url(#graphFill);
                    fill-opacity: 0.5;
                }
            }
            .label {
                font-size: 8px;
            }
        }
        .posts {
            padding: 0;
            text-align: left;
            .card-title {
                font-size: 0.875rem;
                font-weight: 500;
                margin-top: 0;
            }
            .post {
                border: 1px solid #ebebeb;
                border-radius: 2px;
                text-align: left;
                cursor: pointer;
                .img {
                    padding-top: 100%;
                    background-size: cover;
                    position: relative;
                    background-position: center;
                    .views {
                        position: absolute;
                        bottom: 2px;
                        left: 10px;
                        svg {
                            width: 10px;
                            height: 10px;
                            vertical-align: middle;
                            margin-right: 3px;
                        }
                        color: white;
                        font-size: 12px;
                    }
                }
                p {
                    font-size: 12px;
                    line-height: 18px;
                    margin: 4px 4px;
                    color: #2D2D2D;
                    svg {
                        width: 12px;
                        height: 12px;
                        vertical-align: middle;
                        margin: 0 5px 0;
                        fill: #d6d6d6 !important;
                    }
                    span {
                        vertical-align: middle;
                        display: inline-flex;
                        align-items: center;
                        &:first-child {
                            svg  {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 19px;
        height: 19px;
        margin: 2px;
        border: 2px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
        &:nth-child(1) {
            animation-delay: -0.45s;
        }
        &:nth-child(2) {
            animation-delay: -0.3s;
        }
        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
}

.influencer-type-badge{
    border-radius: 50px;
    font-size: 12px;
    padding: 0px 21px;
    line-height: 24px;
    font-weight: 400;
    margin: 0 auto;
    text-align: center;
    display:inline-block;
    background-color: #F6E0E3;
    color: #B5757D;
}


@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.slide-fade-enter-active {
    transition: all 0.2s ease;
}
.slide-fade-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}
