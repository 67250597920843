$skeleton-element-color:  hsla(0, 0%, 90%, 1);

#skeleton-loader {
	overflow: hidden;
	position: relative;
	.loading-bar--container {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		width: 100%;
		// height: 100%;
		z-index: 10;
		margin-top: 2rem;
		margin-bottom: 3rem;
		.loading-bar {
			align-items: center;

			.text {
				font-size: 18px;
				line-height: 28px;
        		margin-bottom: 1rem;
				margin-top: 0;
				color: #141514;
			}
			.bar {
				min-width: 250px;
				background-color: #EBEBEB;
				border-radius: 5px;
				.progress-bar-fill {
					display: block;
					height: 5px;
					width: 0%;
					background-color: #141514;
					border-radius: 5px;
					transition: width 250ms ease-in-out;
				}
			}
		}
	}
	.margin-top{
		margin-top: auto !important;
	}
	.loader-center {
		margin-left: auto !important;
		margin-right: auto !important;
	}

	@mixin loader {
		position: relative;
		overflow: hidden;
    opacity: .3;
		&:after {
			content: '';
			animation: loading 1.2s infinite;
			height: 100%;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			transform: translateX(-100%);
			z-index: 1;
			background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.3), transparent);
		}
	}

	@mixin bar() {
		background: $skeleton-element-color;
		height: 12px;
		border-radius: 20px;
		margin: 0.75rem 0;
		@include loader;
	}

	@mixin circle($width, $height) {
		background: $skeleton-element-color;
		height: $height;
		width: $width;
		border-radius: 100%;
		margin: 1rem 0;
		@include loader;
	}
	@mixin square($width, $height) {
		background: $skeleton-element-color;
		height: $height;
		width: 100%;
		border-radius: 5px;
		margin: 0.5rem 0;
		@include loader;
	}

	// ----------------------------------------------------------------------------------------------------

	.loader-bar {
		@include bar;
	}
	.loader-circle--small {
		@include circle(50px, 50px);
	}
	.loader-circle--medium {
		@include circle(125px, 125px);
	}
	.loader-circle--big {
		@include circle(250px, 250px);
	}
	.loader-square--tiny {
		@include square(500px, 64px);
	}
	.loader-square--small {
		@include square(500px, 120px);
	}
	.loader-square--medium {
		@include square(500px, 180px);
	}
	.loader-square--big {
		@include square(500px, 250px);
	}
	.loader-square--full {
		@include square(100%, 500px);
	}

	/* Animation */
	@keyframes loading {
		from {
			transform: translateX(-100%);
		}
		to {
			transform: translateX(100%);
		}
	}
}
