#banner{
    background: linear-gradient(90deg, #FFE9F0 0%, #FAECDB 51.04%, #D6EBE4 100%);
    cursor: pointer;
    position: relative;
    padding: 5px;
    transition: $transition_default;
    a{
        display: flex;
        align-content: center;
        align-items: center;
        transform: translateY(0);
        transition: $transition_default;
    }
    .text-content{
        pointer-events: none;
        margin: 0 auto;
        p{
            font-size: .875rem;
            margin: 0;
            padding: 0;
        }
        span{
            margin-left: 10px;
            font-size: 12px;
        }
        text-align: center;

        .desktop-text {
          display: block;
        }
        
        .mobile-text {
          display: none;
        }
        
        @media screen and (max-width: 48rem) {
          .desktop-text {
            display: none;
          }
        
          .mobile-text {
            display: block;
          }
        }
    }
    .hide{
        opacity: 0;
    }
}
