.notFound {
  min-height: calc(100vh - 118px);
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.notFound .wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 12px;
}

.notFound .wrapper h2 {
  margin: 0;
}

.notFound .wrapper a {
  background-color: black;
  padding: 12px 24px;
  font-weight: 600;
  color: white;
  transition: all 0.1s ease-in-out;
  border: 1px solid black;
}

.notFound .wrapper a:hover {
  background-color: transparent;
  color: black;
}