#features-top {
	background-color: $light_grey;
	padding-top: 40px;
	img {
		width: 100%;
		margin: 25px 0;
	}

	@include breakpoint(md-down) {
		padding-top: 50px;
		margin-bottom: 200px;
		img {
			transform: translateY(200px);
			margin-top: -150px !important;
			margin: 0 auto;
			width: 80%;
		}
	}
	@include breakpoint(sm-down) {
		img {
			width: 90%;
		}
	}
	.row {
		display: flex;
		align-items: center;
		text-align: center;
		flex-direction: row-reverse;
		@include breakpoint(md) {
			text-align: left;
			flex-direction: row;
		}
		h1{
			line-height: 4rem;
			font-size: 4rem;
		}
		h4 {
			font-weight: 300;
			font-size: 1.125rem;
			line-height: 1.625rem;
			opacity: 0.75;
		}
		.cta {
			.btn:nth-child(2){
				margin-left: 15px;
				padding-left: 10px;
				span{
					padding-left: 20px;
					padding-right: 30px;
				}
			}
			.btn:nth-child(1) {
				border: none;
			}
			margin: 75px 0;
		}
	}
}

#features-body {
	.features-section {
		padding: 30px 25px;
		box-sizing: border-box;
		outline: none !important;
		&.bg-light-green {
			background-color: $light_green;
		}
		&.bg-light-green-accent {
			background-color: $light_green_accent;
		}
		&.bg-light-red {
			background-color: $light_red;
		}
		&.bg-light-red-accent {
			background-color: $yellow_sub;
		}
		&.bg-light-blue {
			background-color: $light_blue;
		}
		&.bg-light-blue-accent {
			background-color: $light_blue_accent;
		}
		@include breakpoint(xs) {
			padding: 50px 35px;
		}
		@include breakpoint(sm) {
			padding: 58px 51px;
		}
		p {
			margin-top: 0;
			margin-bottom: 0;
		}
		h2 {
			margin-bottom: 100px;
			@include breakpoint(sm-down) {
				font-size: 2.625rem;
				margin-bottom: 50px;
			}
		}
		.row {
			display: flex;
			align-items: center;
			.point {
				background-color: $white;
				padding: 26px;
				margin: 16px 0;
				.points-title {
					font-weight: 500;
					margin-bottom: 1rem;
				}
				.points-text {
					font-weight: 350;
					opacity: 0.75;
				}
			}

			img {
				width: 100%;
				height: auto;
				margin-bottom: 1rem;
			}
		}
	}
}
